<template>
  <div class="terms" v-if="page">
    <div class="outer-container">
      <div class="inner-container">
        <div class="terms-content">

          <div v-html="page.elements.page_intro_text.value"></div>

          <ol class="toc">
            <li v-for="section in page.elements.faq_sections.value"
                :key="`toc-item_${modular_content[section].system.id}`">
              <a :href="`#faqs_${modular_content[section].system.id}`">{{
                  modular_content[section].elements.heading.value
                }}</a>
            </li>
          </ol>

          <div class="layout">
            <div class="main">
              <div class="section" v-for="section in page.elements.faq_sections.value"
                   :key="`section_${modular_content[section].system.id}`">
                <h4 :id="`faqs_${modular_content[section].system.id}`">{{
                    modular_content[section].elements.heading.value
                  }}</h4>
                <div class="faq-item" v-for="item in modular_content[section].elements.faq_items.value"
                     :key="`item_${modular_content[item].system.id}`">
                  <h5 class="faq-question" @click="toggleFaqItem">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.386" height="12.202" viewBox="0 0 22.386 12.202"
                         class="absolute top-8 left-4 transition duration-300">
                      <g id="Action_Down_Arrow_Copy_2" data-name="Action_Down Arrow Copy 2"
                         transform="translate(0.681 0.733)">
                        <path id="Path_Copy_2" data-name="Path Copy 2" d="M0,0,10.85,10.083,21,0" fill="none"
                              stroke="#E8490F" stroke-miterlimit="10" stroke-width="2" />
                      </g>
                    </svg>
                    {{ modular_content[item].elements.question.value }}
                  </h5>
                  <div class="faq-answer">
                    <div v-html="modular_content[item].elements.answer.value"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar">
              <div class="panel">
                <h2 class="h4">Still need help?</h2>
                <p>If you have any questions, please reach out to Australian Wine Connect team.</p>
                <div class="cta mt-12">
                  <a href="https://app.smartsheet.com/b/form/0f566bc5273f45248c8ed20b7808dd3e" target="_blank"
                     class="btn-transparent">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Kontent from "@/kontent";
import $ from 'jquery'

export default {
  name: 'Exhibitor',
  metaInfo() {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {
        property: 'og:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {
        name: 'title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {
        name: 'description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      },
      {name: 'twitter:card', content: 'summary_large_image'},
      {
        name: 'twitter:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {
        name: 'twitter:description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      }
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  props: {},
  data() {
    return {
      page: null,
      modular_content: null
    }
  },
  mounted() {
    const self = this
    Analytics.trackPage('Exhibitor')
    Kontent.getItem('exhibitor_page', this.$route.params.slug, 3).then((res) => {
      this.page = res.data.items[0]
      this.modular_content = res.data.modular_content

      setTimeout(function () {
        self.handleTocLinks()
      }, 100)

      setTimeout(function () {
        window.prerenderReady = true
      }, 1000)
    })
  },
  methods: {
    handleTocLinks() {
      $(this.$el).on('click', '.toc a', function (e) {
        e.preventDefault();

        let href = e.target.href
        let hash = href.substring(href.indexOf('#'))
        let scrollTarget = `.terms-content h4${hash}`

        $('html, body').animate({
          scrollTop: $(scrollTarget).offset().top - $('header .main').height() - 30
        })
      });
    },

    toggleFaqItem(e) {
      if ($(e.target).parents('.faq-item').hasClass('active')) {
        $(e.target).parents('.faq-item').removeClass('active')
        $(e.target).parents('.faq-item').find('.faq-answer').stop().slideUp(400)
      } else {
        $(e.target).parents('.faq-item').addClass('active')
        $(e.target).parents('.faq-item').find('.faq-answer').stop().slideDown(300)
      }
    }

    // generateAnchors () {
    //   const self = this
    //
    //   let tocHtml = '<div class="toc"><div class="heading">Table of contents</div><ul>';
    //
    //   $(self.$el).find('h4').each(function(i, el){
    //     tocHtml += '<li><a href="#' + i + '" data-index="' + i + '">' + $(el).text() + '</a>';
    //   });
    //
    //   tocHtml += '</ul></div>';
    //
    //   $(tocHtml).insertAfter($(self.$el).find('h3:first'));
    //
    //   $(self.$el).on('click', '.toc a', function(e){
    //     e.preventDefault();
    //     console.log('click')
    //     const idx = parseInt($(this).attr('data-index'))
    //     $('html, body').animate({scrollTop: $('.terms-content h4').eq(idx).offset().top - $('header .main').height() - 30})
    //   });
    // }
  }
}
</script>

<style lang="scss">
.terms-content {
  margin-top: 30px;
  margin-bottom: 50px;

  h1, h2, h3, h4, h5 {
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: bold;
    text-transform: none;
  }

  ul {
    margin: 1em 0;
    padding-left: 15px;

    > li {
      list-style-type: disc;
    }
  }

  ol {
    margin: 1em 0;
    padding-left: 15px;

    > li {
      list-style-type: decimal;
    }
  }

  h1 {
    font-size: 40px;
    max-width: 800px;
    margin-right: auto;

    @media(min-width: 768px) {
      font-size: 68px;
    }
  }

  p {
    margin-bottom: 1em;
  }

  @media(min-width: 768px) {
    .layout {
      display: grid;
      grid-template-areas: "main sidebar";
      grid-template-columns: minmax(auto, 800px) 280px;
      gap: 60px;
      justify-content: space-between;

      .main {
        grid-area: main;
      }

      .sidebar {
        grid-area: sidebar;
        align-self: start;
        position: sticky;
        top: 20rem;
      }
    }
  }

  .sidebar .panel {
    padding: 30px;
    background-color: #232323;

    h2 {
      margin-top: 0;
    }
  }

  ol.toc {
    overflow: hidden;
    margin: 40px 0 80px;
    padding: 0;

    li {
      position: relative;
      margin-right: 31px;
      margin-bottom: 10px;
      list-style: none;

      a {
        color: #E8490F;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    @media(min-width: 768px) {
      display: flex;
      flex-wrap: wrap;

      li {
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -16px;
          content: "";
          display: block;
          height: 15px;
          width: 1px;
          background-color: #fff;
        }
      }
    }
  }

  .section {
    margin-bottom: 80px;

    h4 {
      margin: 0 0 20px;
      color: #fff;
      font-weight: normal;
    }

    .faq-item {
      border-top: 1px solid #232323;
      transition: background-color .4s;

      h5.faq-question {
        position: relative;
        margin: 0;
        padding: 15px 15px 15px 45px;
        font-size: 16px;
        color: #fff;
        font-weight: normal;
        cursor: pointer;
        user-select: none;
        transition: background-color .4s;

        &:hover {
          background-color: #232323;
        }

        svg {
          transform: rotate(-90deg);
        }
      }

      .faq-answer {
        display: none; // collapsed by default
        opacity: .2;
        transition: opacity .4s;

        > div {
          padding: 15px 15px 30px 45px;

          *:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: #E8490F;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        ul li::marker {
          color: #E8490F;
        }
      }

      &.active {
        background-color: #232323;

        .faq-question {
          svg {
            transform: rotate(0deg);
          }
        }

        .faq-answer {
          opacity: 1;
        }

        + .active {
          border-top-color: #000;
        }
      }
    }
  }
}
</style>
